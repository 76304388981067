<template>
  <div class="article-container">
    <div class="main-wrap">
      <!-- 加载中 -->
      <div v-if="loading" class="loading-wrap">
        <van-loading color="#3296fa" vertical>مازمۇن ىزدەلۋدە...</van-loading>
      </div>
      <!-- /加载中 -->

      <!-- 加载完成-文章详情 -->
      <div class="article-detail" v-if="!loading">
        <van-nav-bar
          title="ارنا ينتەرنەت ساباقحاناسى"
          @click-left="$router.back()"
          @click-right="$router.push('/')"
          fixed
        >
          <div slot="left" style="color: #fff; font-size: 16px">
            قايتۋ
            <van-icon name="arrow-left" size="16px" />
          </div>
          <div slot="right" style="color: #fff; font-size: 16px">
            <van-icon name="wap-home-o" size="18px" />
            باس بەت
          </div>
        </van-nav-bar>

        <!-- 文章标题 -->
        <h1 class="article-title">{{ article.title }}</h1>

        <!-- <div class="category" v-if="detail.category">
          <div
            class="icon-container"
            @click="
              $router.push({ path: '/grid', query: { id: detail.category.id } })
            "
          >
            <van-image class="icon" round :src="detail.category.icon" />
            <span class="ellipsis" style="margin-right: 6px">{{
              detail.category.name
            }}</span>
          </div>
          <span
            v-if="detail.teacher_id"
            @click="
              $router.push({
                name: 'teacher',
                params: { teacher_id: detail.teacher_id },
              })
            "
          >
            <van-icon
              name="user-circle-o"
              style="margin-left: 6px"
              color="#999"
            />
            <span class="teacher">{{ detail.teacher.title }}</span></span
          >
          <span class="time">
            <span>{{ article.created_at }}</span>
            <van-icon name="clock-o" style="margin-right: 3px" />
          </span>
        </div> -->
        <!-- 视频播放器 -->
        <div class="videoControl" v-if="detail.type === 'video'">
          <video-player
            class="video-player vjs-custom-skin"
            ref="videoPlayer"
            :playsinline="true"
            :options="playerOptions"
            @timeupdate="onPlayerTimeupdate($event)"
          ></video-player>
        </div>

        <!-- 音频播放器 -->
        <div class="audioControl" v-else-if="detail.type === 'audio'">
          <aplayer
            ref="aplayer"
            @timeupdate="onTimeupdate"
            :audio="audio"
            :mini="false"
            :lrcType="0"
            :volume="volume"
            :listFolded="true"
          />
        </div>

        <div
          style="font-family: 'Kerwen Tor'"
          v-if="detail.seckill_status === 1 && detail.is_free == 1"
        >
          <van-divider
            :style="{
              color: '#ee0a24',
              borderColor: '#ee0a24',
              padding: '0 16px',
              fontSize: '16px',
              marginBottom: '0',
            }"
            >{{ detail.seckill_name }}</van-divider
          >
          <count-down
            :endTime="vipTime"
            endText="قيمىل اياقتالدى. كەلەر جولعى قيمىلعا قاتىسىڭىز!"
          />
          <p class="vip-desc">قيمىل باعاسى: {{ detail.seckill_price }} يۋان</p>
        </div>
        <!-- 文章展示区域 -->
        <div
          v-if="article.article_text"
          ref="article-content"
          class="article-content markdown-body"
          v-html="article.article_text"
        ></div>
      </div>

      <p class="login-tips">
        باعدارلامانىڭ تىپتىدە كەمەلدى دامۋى ءۇشىن ەڭبەگىمىزدى قولداي ءبىلىڭىز.
        راحىمەت
      </p>

      <!-- 赞赏功能模块 -->
      <div class="appreciates">
        <van-button type="danger" round @click="onTapShowZanshang"
          >قولداۋ ءبىلدىرۋ</van-button
        >
      </div>

      <div class="appreciatesList" v-if="appreciates.length">
        <van-divider
          :style="{
            color: '#666',
            borderColor: '#666',
            padding: '8px 16px 16px',
            margin: 0,
          }"
          >{{ appreciates.length }} ادام قولدادى</van-divider
        >
        <div class="avatars">
          <van-image
            v-for="item in appreciates"
            :key="item.uid"
            radius="4"
            :src="item.user.avatar_url"
          />
        </div>
      </div>
      <!-- /加载完成-文章详情 -->
    </div>

    <van-popup class="payContainer zanshang" v-model="showZanshang" closeable>
      <div>
        <h4>ەڭبەگىمىزدى قولداڭىز</h4>
        <p class="p4" v-if="showPriceList">
          <van-button
            v-for="item in zanShangList"
            :key="item"
            block
            type="danger"
            size="small"
            plain
            @click="_payZanshang(item)"
            >{{ item }} يۋان</van-button
          >
        </p>
        <van-field
          v-model="zanshangPrice"
          input-align="right"
          v-if="!showPriceList"
          placeholder="باعانى جازىڭىز"
          type="number"
        />
        <van-button
          class="submitBtn"
          v-if="!showPriceList"
          type="danger"
          block
          round
          size="small"
          @click="submitZanshang"
          >تۇراقتاندىرۋ</van-button
        >
        <p class="userInput" @click="inputPrice">باسقا باعا</p>
      </div>
    </van-popup>

    <van-tabs
      v-model="active"
      class="tabs"
      v-if="lessons.length > 1"
      color="#38f"
    >
      <van-tab title="ءتىزىم" class="list">
        <van-cell
          class="article-item"
          @click="onSelect(article)"
          v-for="(article, index) in lessons"
          :key="index"
        >
          <div
            slot="title"
            class="title van-multi-ellipsis--l2"
            :class="
              detail.is_free == 1 && article.is_free == 2 ? 'text-grey' : ''
            "
          >
            <p
              style="display: inline-block; margin-left: auto"
              :class="article.id == currentId ? 'text-blue' : ''"
            >
              {{ article.title }}
            </p>
            <!--
                          course   lesson
                    收费     1        2
                    免费     2        1
                    免费     2        2
                    免费     1        1
               -->
            <i
              v-if="
                (detail.is_free == 1 && article.is_free == 1) ||
                (detail.is_free == 2 && article.is_free == 2) ||
                (detail.is_free == 2 && article.is_free == 1)
              "
              class="icon-lock-open1"
              style="color: #999"
            ></i>
            <i
              v-if="detail.is_free == 1 && article.is_free == 2"
              class="icon-lock"
            ></i>
          </div>
        </van-cell>
      </van-tab>
      <van-tab class="description" title="تۇسىنكتەمە">
        <div
          class="markdown-body"
          v-if="detail.description"
          v-html="detail.description"
        ></div>
        <p v-else>قازىرشە تۇسنىكتەمە جازىلمادى!</p>
      </van-tab>
    </van-tabs>

    <template v-if="detail.teacher">
      <div class="hd-title">
        <span class="name">اۆتور تۋرالى</span>
      </div>
      <div
        class="teacher"
        @click="
          $router.push({
            name: 'teacher',
            params: { teacher_id: detail.teacher.id },
          })
        "
      >
        <van-image
          lazy-load
          round
          class="avatar"
          :src="
            detail.teacher.avatar
              ? detail.teacher.avatar
              : '../../assets/avatar.jpg'
          "
          fit="cover"
        />
        <div>
          <h2>{{ detail.teacher.title }}</h2>
          <p>{{ detail.teacher.intro }}</p>
        </div>
      </div>
    </template>

    <div class="hd-title" v-if="courses.length">
      <span class="name">قاتىستى جولدامالار</span>
    </div>
    <CourseItem :hots="courses" />

    <div style="height: 10px"></div>

    <van-popup class="payContainer" v-model="showPay">
      <div>
        <h4>{{ detail.title }}</h4>
        <p class="p1">
          جالپى <span>{{ lessons.length }}</span> مازمۇن، باعاسى
          <span>{{ detail.price }}</span> يۋان
        </p>
        <p class="p3" v-if="detail.is_vip == 1">
          (VIP مۇشە بولساڭىز تەگىن كورەسىز)
        </p>
        <p class="p3" v-if="detail.is_vip == 2">
          (بۇل مازمۇن تەك جەكە ساتىلادى)
        </p>
        <p class="p4">
          <template v-if="detail.is_vip == 1">
            <van-button type="danger" round @click="_pay" size="small"
              >جەكە ساتىپ الۋ</van-button
            >
            <van-button type="primary" round to="/vip" size="small"
              >VIP مۇشە بولۋ</van-button
            >
          </template>
          <van-button
            class="singleBuy"
            block
            v-if="detail.is_vip == 2"
            type="danger"
            round
            size="small"
            @click="_pay"
            >جەكە ساتىپ الۋ</van-button
          >
        </p>
      </div>
    </van-popup>
  </div>
</template>

<script>
import wx from 'weixin-js-sdk'
import { getArticleById, getArticles, courseDetail } from '@/api/article'
import { getUrl } from '@/api/getTxUrl'
import { getItem } from '@/utils/storage'
import CourseItem from '@/components/Course-item'
import { getRecomList } from '@/api/home'
import { initShareInfo } from '@/utils/util'
import { pay, payResult } from '@/api/pay'
import { wechatRedirect } from '@/api/user'
import {
  createAppreciates,
  orderPayStatus,
  getAppreciates
} from '@/api/appreciates'
import CountDown from '@/components/CountDown'

export default {
  name: 'ArticleIndex',
  // 给所有的后代组件提供数据
  // 注意：不要滥用，如果一个父组件里面的所有子组件（所有后代组件）都需要的话可以这么传过来
  provide () {
    return {
      parent_id: this.parent_id,
      type: this.type
    }
  },
  data () {
    return {
      loading: true,
      detail: {}, // 父类详情
      article: {}, // 文章详情
      errStatus: 0, // 失败的状态码
      userInfo: {}, // 用户信息
      txUrl: '',
      lessons: [],
      currentId: '',
      playerOptions: {
        playbackRates: [0.7, 1.0, 1.5, 2.0], // 播放速度
        autoplay: false, // 如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: '', // 这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
            src: '' // url地址
          }
        ],
        poster: '', // 你的封面地址
        // width: document.documentElement.clientWidth, //播放器宽度
        notSupportedMessage: '', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true // 全屏按钮
        }
      },
      courses: [],
      audio: {
        name: '👈🏻  كىنوپكانى باسىپ تىڭداڭىز',
        artist: '',
        url: '',
        cover: ''
      },
      volume: 0.7,
      showPay: false,
      active: 2,
      openId: '',
      showZanshang: false,
      zanShangParams: {},
      appreciates: [],
      showPriceList: true,
      zanshangPrice: null,
      zanShangList: [1, 2, 5, 10, 20, 50, 100, 200, 500],
      vipTime: new Date('2022/11/11 23:59:59').getTime() / 1000 + ' '
    }
  },
  components: {
    CourseItem,
    CountDown
  },
  watch: {
    $route (to) {
      if (to.fullPath === to.path) {
        // 重新渲染数据
        this.getCourseDetail()
      }
    }
  },
  computed: {
    player () {
      return this.$refs.videoPlayer.player
    }
  },
  mounted () {
    const vipStatus = getItem('vipStatus')
    this.vipStatus = vipStatus
    setTimeout(() => {
      const userInfo = getItem('userInfo')
      const openId = this.$cookies.get('openId')
      this.openId = openId
      if (userInfo) {
        this.userInfo = userInfo
        this.getCourseDetail()
      }
    }, 600)
  },
  destroyed () {
    // show share menu
    wx.showOptionMenu()
    if (['audio', 'article'].includes(this.$route.params.type)) {
      this.audio.url = ''
      this.audio.name = ''
      this.audio.cover = ''
    }
  },
  methods: {
    onSelect (item) {
      if (parseInt(this.detail.is_free) === 1 && parseInt(item.is_free) === 2) {
        this.showPay = true
        return
      }

      if (this.$route.params.type === 'video') {
        this._getTxUrl({
          vid: item.wxv_id,
          url: item.gz_url,
          local_url: item.local_url
        })
      }
      if (['audio', 'article'].includes(this.$route.params.type)) {
        this.audio.url = item.gz_url
        this.audio.name = item.title
      }
      this.article = item
      this.article.created_at = item.created_at.split(' ')[0]
      this.currentId = item.id
      this.show = false
      document.documentElement.scrollTop = 0
    },

    // 当触发上拉加载更多的时候调用该函数
    async getLessons () {
      try {
        // 1. 请求获取数据
        const { data } = await getArticles({
          vid: this.$route.params.parent_id,
          m_show: 'N'
        })
        // 2. 把数据添加到 list 数组中
        this.lessons = data.data.reverse()
        this.currentId = this.lessons[0].id
        this.currentVideo = this.lessons[0]
        this.loadArticle()
      } catch (err) {
        console.error(err)
      }
    },

    async loadArticle () {
      this.loading = true
      try {
        const { data } = await getArticleById(this.currentId)
        this._getTxUrl({
          vid: data.wxv_id,
          url: data.gz_url,
          local_url: data.local_url
        })
        if (['audio', 'article'].includes(this.$route.params.type)) {
          this.audio.url = data.gz_url
          this.audio.name = data.title
        }

        this.article = data
        this.article.created_at = data.created_at.split(' ')[0]
      } catch (err) {
        console.error(err)
      } finally {
        this.loading = false
      }
    },

    async getCourseDetail () {
      try {
        const { data } = await courseDetail({
          vid: this.$route.params.parent_id
        })

        // 如果有限购活动执行以下代码
        if (data.course.seckill_day) {
          const limitedTime = data.course.seckill_day
            .split('&')[1]
            .toString()
            .replace(/-/g, '/')
          const vipTime = new Date(limitedTime).getTime() / 1000 + ' '

          const nowTime = new Date()
          const endTime = new Date(vipTime * 1000)
          const t = endTime.getTime() - nowTime.getTime()
          if (t > 0) {
            data.course.price = data.course.seckill_price
            this.vipTime = vipTime
          } else {
            data.course.seckill_status = 2
          }
        }

        this.detail = data.course
        this.getRecomList()
        if (data.course.type === 'audio') this.audio.cover = data.course.image
        if (data.course.type === 'video') {
          this.playerOptions.poster = data.course.image
        }
        this.getLessons()
        this.getAllAppreciates()

        wx.ready(() => {
          initShareInfo({
            title: `${data.course.title} - ارنا ساباقحاناسى`, // 分享标题
            imgUrl: data.course.image // 分享图标
          })
        })
      } catch (error) {
        console.error(error)
      }
    },

    async _getTxUrl ({ vid, url, local_url: localUrl }) {
      if (this.$route.params.type === 'video') {
        if (localUrl) {
          this.playerOptions.sources[0].src = localUrl
        } else {
          const { data: tencent } = await getUrl({ vid, url })
          this.playerOptions.sources[0].src = tencent
        }
      }
      this.loading = false
    },

    // 当前播放位置发生变化时触发。
    onPlayerTimeupdate () {
      if (
        parseInt(this.detail.is_free) !== 2 &&
        parseInt(this.article.is_free) === 2
      ) {
        this.$refs.videoPlayer.player.pause()
        this.showPay = true
      }
    },

    // 音频开始播放监听事件
    onTimeupdate () {
      if (
        parseInt(this.detail.is_free) !== 2 &&
        parseInt(this.article.is_free) === 2
      ) {
        this.$refs.aplayer.pause()
        this.showPay = true
      }
    },

    async _pay () {
      const _this = this
      _this.showPay = false
      const openId = _this.$cookies.get('openId')
      if (!openId) {
        location.href = `${wechatRedirect}?url=${encodeURIComponent(
          location.href
        )}&scope=snsapi_userinfo`
      }

      // 支付请求参数
      const params = {
        goods_name: _this.detail.title,
        goods_id: _this.detail.id,
        price: _this.detail.price,
        openId
      }
      const { code, data } = await pay(params)
      if (code === 200) {
        wx.chooseWXPay({
          timestamp: data.args.timeStamp,
          nonceStr: data.args.nonceStr,
          package: data.args.package,
          signType: data.args.signType,
          paySign: data.args.paySign,
          success (res) {
            if (res.errMsg === 'chooseWXPay:ok') {
              payResult({
                payState: 'success',
                goods_name: _this.detail.title,
                goods_id: _this.detail.id,
                price: _this.detail.price,
                order_number: data.orderInfo.order_number
              })

              setTimeout(() => {
                _this.getCourseDetail()
              }, 500)
            } else if (res.errMsg === 'chooseWXPay:fail') {
              _this._userCancel()
            }
          },
          cancel: function () {
            _this._userCancel()
          },
          fail: function (res) {
            alert(res.message || 'تەلەفوننان اقشا تولەڭىز')
          }
        })
      }
    },

    // 用户取消付款提示
    async _userCancel () {
      try {
        await this.$dialog.confirm({
          title: 'ەسكەرتۋ',
          message:
            'ءسىز اقشا تولەۋدى كۇشىنەن قالدىردىڭىز، قايتالاي اقشا تولەۋدى باسىڭىز!',
          confirmButtonText: 'اقشا تولەۋ',
          cancelButtonText: 'قالدىرۋ'
        })
        this._pay()
      } catch (error) {
        console.log(error)
      }
    },

    async getRecomList () {
      try {
        const res = await getRecomList({
          recommend: 1,
          pagesize: 4,
          random: 'Yes',
          cateId: this.detail.cate_id
        })
        if (res.code === 200 && res.errorCode === 0) {
          this.courses = res.data.data
        }
      } catch (error) {
        console.log(error)
      }
    },

    async getAllAppreciates () {
      try {
        const { data } = await getAppreciates(this.$route.params.parent_id)
        this.appreciates = data.data
      } catch (error) {
        console.error(error)
      }
    },

    onTapShowZanshang () {
      this.showZanshang = true
    },

    async _payZanshang (item) {
      const _this = this
      const openId = _this.$cookies.get('openId')
      if (!openId) {
        location.href = `${wechatRedirect}?url=${encodeURIComponent(
          location.href
        )}&scope=snsapi_userinfo`
      }

      _this.zanShangParams = {
        memberName: `${item} يۋان قولداۋ-ارنا ساباقحاناسى`,
        openId: openId,
        price: item,
        memberId: _this.$route.params.parent_id
      }

      _this.showZanshang = false

      const { code, data } = await createAppreciates(_this.zanShangParams)
      if (code === 200) {
        wx.chooseWXPay({
          timestamp: data.timeStamp,
          nonceStr: data.nonceStr,
          package: data.package,
          signType: data.signType,
          paySign: data.paySign,
          success: async (res) => {
            if (res.errMsg === 'chooseWXPay:ok') {
              orderPayStatus('success', {
                ..._this.zanShangParams,
                orderNumber: data.order_number
              })

              setTimeout(() => {
                location.reload()
              }, 500)
            } else if (res.errMsg === 'chooseWXPay:fail') {
              _this._userCancel()
            }
          },
          fail: async (res) => {
            alert(res.message || 'اقشا تولەۋ ءساتسىز بولدى')
          }
        })
      }
    },
    inputPrice () {
      this.showPriceList = !this.showPriceList
    },
    submitZanshang () {
      if (!this.zanshangPrice) {
        this.$toast('قولدايتىن باعانى جازىڭىز')
        return
      }
      this._payZanshang(this.zanshangPrice)
    }
  }
}
</script>

<style scoped lang="less">
@import "./css/github-markdown.css";
.vip-tips {
  direction: rtl;
  font-size: 28px;
  color: #ee0a24;
  padding: 30px;
  text-align: justify;
}

.article-container {
  direction: rtl;
  height: 100vh;

  .main-wrap {
    overflow-y: scroll;
    background-color: #fff;
    margin-bottom: 10px;
    padding-bottom: 20px;

    .qrCode {
      text-align: center;
      padding-bottom: 40px;
      img {
        width: 400px;
        height: 400px;
        border: 1px solid #e3e7ea;
        border-radius: 8px;
      }

      p {
        font-size: 36px;
        color: #888;
        margin-bottom: 30px;
      }
    }
  }

  .article-detail {
    position: relative;
    font-family: Arial, Helvetica, sans-serif !important;
    .videoControl {
      margin: 30px;
    }
    .audioControl {
      width: 100%;
      display: flex;
      justify-content: center;
      margin: 20px auto;
      direction: ltr;
      /deep/ .aplayer {
        width: 92%;
        .aplayer-title {
          position: absolute;
          right: 20px;
          color: #666;
          direction: rtl;
          width: 500px;
          height: 40px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        .aplayer-list {
          direction: rtl;

          .aplayer-list-index {
            margin-right: 0;
            margin-left: 12px;
          }
        }
      }
    }
    .article-title {
      font-size: 36px;
      padding: 50px 32px 0;
      color: #3a3a3a;
      text-align: center;
      margin: 80px auto 0;
    }

    .category {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 24px;
      color: #999;
      width: 91%;
      margin: 0 auto 30px;

      .icon-container {
        display: inline-flex;
        align-items: center;
        .icon {
          width: 40px;
          height: 40px;
          margin-top: -10px;
        }
        > span {
          display: inline-block;
          height: 40px;
          line-height: 40px;
        }
      }

      > span.time {
        display: inline-flex;
        align-items: center;
        > span {
          direction: ltr;
          font-family: Arial, Helvetica, sans-serif;
        }
      }

      .teacher {
        font-size: 22px;
      }

      .van-button {
        height: 34px;
        padding-top: 5px;
        margin-top: -10px;
        font-size: 22px;
      }
    }

    .created_at {
      font-size: 24px;
      color: #999;
      direction: ltr;
      font-family: Arial, Helvetica, sans-serif;
      padding-left: 10px;
      margin-bottom: 20px;
    }

    .article-content {
      padding: 0 32px 20px;
      text-align: justify;
    }

    .article_pic {
      padding: 20px;
    }

    .content-container {
      position: relative;
      min-height: 200px;
      overflow: hidden;
      text-align: center;

      .price_tips {
        position: absolute;
        width: 100%;
        bottom: 0;
        height: 300px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0), #fff);
        display: flex;
        justify-content: center;
        .van-button {
          margin-top: 200px;
        }
      }
    }
  }

  // 教师模块
  .teacher {
    display: flex;
    align-items: center;
    direction: rtl;
    background-color: #fff;
    padding: 20px;
    margin-bottom: 10px;
    .avatar {
      width: 100px;
      height: 100px;
      flex-shrink: 0;
      margin-left: 20px;
    }
    > div {
      display: flex;
      flex-direction: column;
      h2 {
        font-size: 30px;
        margin-bottom: 10px;
      }

      p {
        font-size: 24px;
        color: #999;
      }
    }
  }

  // 切换模块
  .tabs {
    direction: rtl;
    .title {
      font-size: 30px;
      > i {
        margin-right: 20px;
      }
    }
    .description {
      font-size: 30px !important;
      padding: 20px 30px;
      background-color: #fff;
      img {
        width: 100% !important;
      }
    }

    .list {
      font-size: 26px;
      padding-top: 10px;
      background-color: #fff;
    }
  }

  .payContainer {
    width: 600px;
    border-radius: 20px;
    text-align: center;
    padding: 40px 30px 30px 30px;
    box-sizing: border-box;
    direction: rtl;
    h4 {
      font-size: 34px;
      margin-bottom: 20px;
    }

    p.p1 {
      font-size: 28px;
      margin-bottom: 20px;
      span {
        color: #ee0a24;
      }
    }

    p.p2 {
      font-size: 28px;
      margin-bottom: 20px;
      color: #999;
    }

    p.p3 {
      font-size: 28px;
      color: #999;
      margin-bottom: 30px;
    }

    p.p4 {
      display: flex;
      justify-content: space-around;
      align-items: center;
      direction: rtl;

      .singleBuy {
        width: 400px;
        height: 70px;
        font-size: 30px;
      }
    }
  }

  .zanshang {
    p.p4 {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      .van-button {
        width: 160px !important;
        font-size: 30px !important;
        margin: 10px;
      }
    }

    p.userInput {
      color: rgb(53, 81, 136);
      font-size: 30px;
      margin-top: 30px;
    }

    .submitBtn {
      margin-top: 30px;
      width: 60%;
      height: 80px;
      margin: 30px auto;
    }
  }

  .appreciates {
    display: flex;
    justify-content: center;
    background-color: #fff;
    color: #fff;
    padding: 20px 0;
    .van-button {
      width: 300px;
      height: 80px;
    }
  }

  .appreciatesList {
    background-color: #fff;
    .avatars {
      width: 80%;
      margin: auto;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      .van-image {
        width: 60px;
        height: 60px;
        margin: 6px;
      }
    }
  }

  .login-tips {
    margin: 0 40px;
    color: #999;
    font-size: 28px;
    text-align: justify;
  }

  .vip-desc {
    font-size: 32px;
    direction: rtl;
    text-align: center;
    color: #ee0a24;
    margin: 0 20px 40px;
  }

  .countDown {
    box-shadow: none;
    margin: 0;
  }
}
</style>
